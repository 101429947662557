export default {
  "seo": {
    "title": 'CG-招聘'
  },
  "app": {
    "zh": "简体",
    "en": "EN",
    "vi": "Việt",
    "tw": "繁體",
    "tjcg": "提交成功"
  },
  "index": {
    "qbgw": "全部崗位",
    "wei": '位',
    "Phone": "電話",
    "Email": "郵箱",
    "close": "關閉"
  },
    //繁体
  "header": {
    "index": "首頁",
    "environmental": "環境展示",
    "salary": "薪酬福利",
    "staffcenter": "員工中心",
    "join": "加入我們",
    "toudi": "立即投遞",
    "sousuo": "輸入崗位進行蒐索",
    "title":"辦公環境",
    "title1":"住宿環境",
    "title2":"餐廳環境",
    "title3":"周邊環境",    
  },
  //环境
  "huanjin":{
    "bghj": "辦公環境",
    "zshj": "住宿環境",
    "cthj": "餐廳環境",
    "wenzidiv":"CG集团以菲律賓馬尼拉大都會中央商務區馬卡提為總部，為員工打造了舒適，創新，安全，體現CG文化特色的辦公環境。",
    "xuanxiang":"前台環境",
    "xuanxiang1":"工作環境",
    "xuanxiang2":"會議室環境",
    "xuanxiang3":"休閒區環境",
    "zhusutitle":"集團為員工提供1-4人間酒店式公寓，自帶免費游泳池跟健身房，提供免費全套生活用品以及床上用品，廚房用品以及家電齊全，給員工家的感覺。",
    "rooms":"單人間",
    "rooms1":"雙人間",
    "rooms2":"情侶房",
    "rooms3":"四人間",
    "Restaurant":"集團提供24小時開放的自助餐廳，菜品覆蓋多個國家的美食佳餚，選用當季新鮮食材，配以精湛的烹飪手法，各式菜餚可以滿足不同的味蕾需求。",
    "roomswenzi":"營養的早餐",
    "roomswenzi1":"豐盛的午餐",
    "roomswenzi2":"可口的晚餐",
    "roomswenzi3":"烘焙甜點",
    "roomswenzi4":"當季水果",
    "roomswenzi5":"果汁機",
    "roomswenzi6":"咖啡機",
    "roomswenzi7":"奶茶機",
    "zhoubianwenzi":"集團周邊集合了多個大型的購物商場，便利店，各個國家美食餐館，步行3-5分鐘到達，讓你的出行更加便利。",
    "zhouweixunaxiang":"大型購物商場",
    "zhouweixunaxiang1":"水果超市",
    "zhouweixunaxiang2":"7-11便利店",
    "zhouweixunaxiang3":"星巴克咖啡店",
    "zhouweixunaxiang4":"奶茶店",
    "zhouweixunaxiang5":"燒烤店",
    "zhouweixunaxiang6":"火鍋店",

  },
  //福利
  "fuli":{
    "title":"薪資收入",
    "title1":"常規福利",
    "title2":"節假日福利",
    "title3":"獎勵激勵",
    "wenzi":"集團亦有著高於同行業",
    "wenzi1":"20%",
    "wenzi2":"的薪酬酬勞，完善跟個性化的員工福利，只為回報你的盡心拼搏。",
    "xinzi":"基本底薪",
    "xinzititle":"80000PESO起步，根據不同崗位來區分。",
    "quanqin":"全勤獎",
    "quanqint1":"每月出勤無異常者發放全勤獎",
    "quanqintit":"每月出勤無異常者（包括遲到，早退，漏打卡，請假等），發放4000PESO。",
    "quanqintit1":"每月五號準時發放住房補貼",
    "nianzhong":"年終獎13薪-16薪",
    "nianzhongtit":"每年根據工齡發放13薪-16薪。",
    "zhongcheng":"忠誠獎",
    "zhongchengtit":"入職滿一年申請領取忠誠獎，推廣部門可領取240000PESO，其他部門可領取160000PESO。",
    "changguitit":"內推獎金",
    "changguiwenzi":"内推推广部门奖励80000PESO，内推其他部门奖励48000PESO，内推技术人才以及其他部门组长以上职位人才，奖励其对应一个月底薪。",
    "choujiang":"早到抽獎",
    "choujiangtit":"在職者每天上班早到10分鐘，可以參與每月1號早到抽獎福利，獎品為最新款蘋果手機一部。",
    "lvyou":"集體旅遊",
    "lvyouwenzi":"集團每個月會組織優秀的員工，出海旅遊以及出國旅遊，全程費用集團承擔。",
    "jieri":"節日禮金",
    "jieriwenzi":"端午，中秋，元旦在職者每人發放5000PESO，春節發放10000PESO。",
    "jieriwenzi1":"大型節日均有禮金紅包發放",
    "shengri":"生日禮金",
    "shengriwenzi":"以護照證件為準，當日可領取7000PESO",
    "shengriwenzi1":"以護照證件為準，當日可領取7000PESO",
    "tuanjian":"團建禮金",
    "tuanjianwenzi":"每月5號准時發放1000PESO,另外不定期組織集體團建活動。",
    "tuanjianwenzi1":"每月五號發放團建禮金",
    "xiujia":"帶薪休假",
    "xiujiawenzi":"享受帶薪休婚假，喪假，產假等相關法定節假期，一年享受15天帶薪回國休假。",
    "waisu":"外宿補貼",
    "waisuwenzi":"每月5號准時發放外宿補貼，金額以專員，組長，主管及以上來區分，可領取18000PESO-30000PESO。",
    "jiaotong":"交通補貼",
    "jiaotongwenzi":"每月5號准時發放交通補貼，金額以主管以上來區分，可領取2000PESO。",
    "huafei":"話費補貼",
    "huafeiwenzi":"每月1號准時充值600PESO的電話費用。",
    "yilaio":"醫療保險",
    "yiliaowenzi":"為所有已辦理工簽（PWP或者9G）的員工辦理菲律賓醫保卡。此醫保卡可在菲律賓指定醫院看病抵扣醫療費用。公司會每個月為在職員工繳納醫保費用，確保醫保卡的長期有效。",
    "jinshi":"近視微型手術補貼",
    "jinwenzi":"為近視的在職員工提供近視微型手術費用補貼，根據工齡來報銷手術費用。",
    "zhufang":"住房補貼",
    "zhufangwenzi":"每月五號準時發放住房補貼",
    "shisan":"十三薪-十六薪",
    "shisanwenzi":"根據工齡發放年終獎",
    "liaojie":"想了解更多的福利？"
  },
  //员工
  "yuangong":{
    "tltle":"員工活動",
    "tltle1":"員工俱樂部",
    "tltle2":"員工故事",
    "titlewenzi":"以豐富多彩的活動，深入人心的文化品牌，將集團文化的信念不斷傳承。",
    "xuanxiang":"團隊聚餐",
    "xuanxiang1":"包機出遊",
    "xuanxiang2":"體育活動",
    "xuanxiang3":"拓展活動",
    "xuanxiang4":"集團年會",
    "yuangongwenzi":"集團為豐富員工的業餘生活，耗資巨資建設員工活動俱樂部，配備頂級活動設施，24小時對在職員工開放。",
    "gushi":"員工故事",
    "gushiwenzi":"聽師兄師姐說",
    "wenzitlt":"懷著一顆想出來闖蕩的心，從教育界跳到了科技領域，CG集團有著特色的集團文化跟人性管理，讓你感受到家的溫暖。",
    "wenzitlt1":"自己走錯的路，只有靠自己去挽回。很幸運的遇到了好的開始，好的公司，讓我能夠快速從困境中走出。",
    "diqu":"馬來西亞 面試官",
    'gdjc':'更多精彩',
    'hsgw':'立即搜索合適崗位',
    'tqs':'台球室',
    'jss':'健身室',
    'ktv':'KTV',
    'ct':'團建餐廳',
    'djs':'電競室'
  },
  "center":{
    "bang":"環境展示",
    "wenzi":"CG集团",
    "wenzidiv":"以菲律賓馬尼拉大都會中央商務區馬卡提為總部，為員工打造了舒適，創新，安全，體現CG文化特色的辦公環境。",
    "jiaru":"加入CG，攜手共創美好未來！",
    "footer":"網申/內推"
  },
  'story':{
    'Gcontent':'我是Gerry，公司的同事都喜歡喊我熱裡。',
    'Garea':'越南',
    'Gzhiwei':'測試工程師',
    'Tcontent':'我叫Tina，2018年馬來西亞博特拉大學教育專業畢業，當過一段時間的中學老師。 2021年加入CG集團，現在在做面試官的相關工作。',
    'Tarea':'馬來西亞',
    'Tzhiwei':'面試官',
    'Scontent':'我是Sunny，每天我都在為我和我的貓能住上大house而努力。',
    'Sarea':'馬來西亞',
    'Szhiwei':'財務',
    'Mcontent':'我是Martin 馬丁，一個土生土長的印尼人。在高中畢業後，我和很多印尼人一樣，在沒有高學歷的文化背景下，四處漂泊，大廠小廠都待過後，我發現印尼這邊變了，這裡的工資水平永遠止步不前，每個月賺的錢勉強的養活自己。存不下一點積蓄。慢慢發現，出去奮鬥才能改變現狀。',
    'Marea':'印尼',
    'Mzhiwei':'人資招聘',
    'Econtent':'我叫eric，在海外工作5年了，加入4家大大小小的公司，在前公司裡，基本沒有個人能力與職業技能的培養，只是讓我們用自己的方式，像無頭蒼蠅式的去開發客戶，業績不足各種扣薪，加班，沒有方法與業績平平的我也只有被辭退的命運。當時讓我失去了對行業銷售的熱情。',
    'Earea':'馬來西亞',
    'Ezhiwei':'市場主管',
    'Kcontent':'我是Kent，18年畢業後加入CG集團，工作這幾年，從一個職場新人到逐漸可以獨擋一面，我時常感覺自己像一塊海綿，瘋狂吸收著公司、團隊輸出給我們的養分。',
    'Karea':'越南',
    'Kzhiwei':'客服',

  },
  "jianli":{
    "title":"填寫簡歷",
    "xingming":"姓名",
    "xingbie":"性别",
    "guoji":"國籍",
    "phone":"聯係電話",
    "hunyin":"婚姻狀況",
    "yihun":"已婚 ",
    "weihun":"未婚",
    "chudata":"出生日期 ",
    "age":"年齡",
    "dizhi":"居住地址",
    "unmber":"護照號碼",
    "youxiao":"護照有效期",
    "lianxi":"聯繫方式",
    "zhanghao":"賬號",
    "yingpin":"應聘崗位",
    "tuijian":"是否有內部人員推介",
    "tuijianname":"推介人姓名"
  },
  "jianliwz":{
    "title":"個人照片",
    "title1":"上傳格式：png、jpg、jpeg、gif，最大支持5M",
    "title2":"自我介紹視頻 ",
    "title3":"上傳格式: mp4, mov, wmv, gif, 最大支持100M",
    "title4":"教育背景",
    "title5":"學校",
    "title6":"學歷",
    "title7":"專業",
    "title8":"就讀時間",
    "title9":"工作經歷",
    "titlewenzi1":"公司",
    "titlewenzi2":"職位",
    "titlewenzi3":"工作時間",
    "titlewenzi4":"工作内容",
    "titlewenzi5":"語言能力",
    "titlewenzi6":"附件上傳",  
    "titlewenzi7":"簡歷附件",  
    "titlewenzi8":"可上傳2個附件，每個不超過100M,支持格式: doc, docx, xls.. ",  
    "titlewenzi9":"可上傳2個附件，每個不超過100M,支持格式: doc, docx, xls.. ",
    "zuopin":"作品附件",
    "geshi":"全部格式",
    "xmbnwk": "姓名不能為空",
    "qsrnr": "請輸入內容",
    "ypgwbnwk": "應聘崗位不能為空"
  },
  "joinUs": {
    "zzzw": "在招職位",
    "sx": "篩選",
    "zzlb": "職位類別",
    "gzdd": "工作地點",
    "dqwz": "當前位置",
    "gwxq": "崗位需求",
    "qc": "清除",
    "nkngxqdzw": "你可能感興趣的職位",
    "scwzjlhqzwtj": "上傳完整簡歷，獲取職位推薦",
    "scjl": "上傳簡歷",
    "kstd": "快速投遞",
    'djsc': '點擊上傳',
    'yes': '是',
    'no': '否',
    'man': '男',
    'woman':'女',
    'add':'添加',
    'kzw':'去看看職位',
    'cjzp':'您好，歡迎參與CG社會招聘！',
    'wsjl':'請完善簡歷後投遞您心動的職位吧',
    'wynl':'外語能力',
    'dqwz': '當前位置',
    'gwxq': '崗位需求',
    'xl': '學歷',
    'kstd': '快速投遞',
    'ssbm': '所屬部門',
    'gznx': '工作年限',
    'jcxx': '基礎信息',
    'zwms': '職位描述',
    'zwyq': '職位要求',
    'zwdy': '職位待遇',
    'tjjl': '提交简历',
    'qxz': "請選擇"
  },
  'footer':{
    'xgwz':'相關網站',
    'cg':'CG官網',
    'bq':'CG集團 版權所有',
    'dt':'實時動態與招聘信息，關注我們或聯繫我們',
  }
}
