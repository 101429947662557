export default {
  "seo": {
    "title": 'CG-chiêu'
  },
  "app": {
    "zh": "简体",
    "en": "EN",
    "vi": "Việt",
    "tw": "繁體",
    "tjcg": "Submitted successfully"
  },
  "index": {
    "qbgw": "Toàn bộ vị trí",
    "wei": 'Vị trí',
    "Phone": "Điện thoại",
    "Email": "Email",
    "close": 'đóng'
  },
  //越南
  "header": {
    "index": "Trang đầu",
    "environmental": "Môi trường",
    "salary": "Lương và Phúc lợi",
    "staffcenter": "Trung tâm nhân viên",
    "join": "Tham gia",
    "toudi": "Giao hàng ngay lập tức  ",
    "sousuo": "Vào vị trí tìm kiếm",
    "title":"Môi trường làm việc",
    "title1":"Môi trường kí túc xá",
    "title2":"Môi trường nhà ăn",
    "title3":"Môi trường xung quanh",
  },
  //环境
  "huanjin":{
    "bghj": "Môi trường văn phòng",
    "zshj": "Khách sạn",
    "cthj": "餐廳環境",
    "wenzidiv":"Tập đoàn CG có trụ sở chính tại Makati, khu thương mại trung tâm của Metro Manila, Philippines, chúng tôi tạo ra môi trường làm việc thoải mái, mới mẻ, an toàn, thể hiện văn hóa đặc sắc của CG đến nhân viên.",
    "xuanxiang":"Môi trường quầy lễ tân",
    "xuanxiang1":"Môi trường làm việc",
    "xuanxiang2":"Môi trường phòng họp",
    "xuanxiang3":"Môi trường khu giải trí",
    "zhusutitle":"Tập đoàn cung cấp cho nhân viên căn hộ kiểu khách sạn cho 1-4 người ở, miễn phí hồ bơi và phòng tập gym, cung cấp miễn phí các nhu cầu yếu phẩm hằng ngày và chăn ga gối đệm, đầy đủ đồ dùng nhà bếp và gia dụng, mang lại cảm giác như đang ở nhà cho nhân viên.",
    "rooms":"Phòng đơn",
    "rooms1":"Phòng cho 2 người",
    "rooms2":"Phòng cho cặp đôi",
    "rooms3":"Phòng 4 người",
    "Restaurant":"Tập đoàn cung cấp nhà ăn kiểu buffet mở cửa liên tục 24 giờ với các món ăn ngon đến từ nhiều quốc gia, sử dụng nguyên liệu tươi ngon theo mùa cùng kĩ thuật nấu nướng tuyệt vời  sẽ đáp ứng nhu cầu vị giác khác nhau của nhân viên.",
    "roomswenzi":"Buổi sáng dinh dưỡng",
    "roomswenzi1":"Bữa trưa thịnh soạn",
    "roomswenzi2":"Bữa tối đặc sắc",
    "roomswenzi3":"Tráng miệng bánh ngọt",
    "roomswenzi4":"Trái cây tươi theo mùa",
    "roomswenzi5":"Nước ép trái cây",
    "roomswenzi6":"Máy pha cà phê",
    "roomswenzi7":"Máy pha trà sữa",
    "zhoubianwenzi":"Xung quanh Tập đoàn có nhiều cửa hàng mua sắm quy mô lớn, cửa hàng tiện lợi và nhiều nhà hàng đến từ đa quốc gia,bạn chỉ đi bộ 3-5 phút là đến, rất thuận tiện.",
    "zhouweixunaxiang":"Cửa hàng mua sắm quy mô lớn",
    "zhouweixunaxiang1":"Siêu thị trái cây",
    "zhouweixunaxiang2":"Cửa hàng tiện lợi 7-11",
    "zhouweixunaxiang3":"Cửa hàng cà phê Starbucks" ,
    "zhouweixunaxiang4":"Nhà hàng thịt", 
    "zhouweixunaxiang5":"Nướng ngoài trời" ,
    "zhouweixunaxiang6":"Nhà hàng lẩu" 
  },
  //福利
  "fuli":{
    "title":"Thu nhập",
    "title1":"Phúc lợi theo quy định",
    "title2":"Phúc lợi lễ tết",
    "title3":"Phần thưởng khích lệ",
    "wenzi":"Tập đoàn có đãi ngộ cao hơn",
    "wenzi1":"20%",
    "wenzi2":"so với các công ty khác cùng nghành,hoàn thiện và cá nhân hóa phúc lợi của nhân viên, điều này là để thưởng cho sự chăm chỉ của bạn.",
    "xinzi":"Lương cơ bản",
    "xinzititle":"từ 80000PESO,mức lương khác nhau theo từng vị trí.",
    "quanqin":"Thưởng chuyên cần",
    "quanqint1":"Nhân viên tuân thủ nội quy chuyên cần sẽ được nhận tiền chuyên cần mỗi tháng",
    "quanqintit":"4000PESO nếu không vi phạm các lỗi sau (bao gồm đi trễ, về sớm, quên đánh thẻ, phép riêng, phép bệnh).",
    "nianzhong":"Lương tháng 13-16",
    "quanqintit1":"Trợ cấp nhà ở được phát vào ngày 5 hằng tháng",
    "nianzhongtit":"Lương tháng 13-16 được trả dựa trên thâm niên.",
    "zhongcheng":"Tiền thưởng trung thành",
    "zhongchengtit":"làm việc tại công ty 1 năm có thể chọn nhận tiền thưởng trung thành, bộ phận thị trường có thể nhận 240000PESO, các bộ phận khác có thể nhận được 160000PESO.",
    "changguitit":"Phần thưởng giới thiệu nội bộ",
    "changguiwenzi":"Nhân viên trong công ty giới thiệu nhân sự cho bộ phận thị trường sẽ nhận được phần thưởng 80000PESO, với các bộ phận khác sẽ nhận được phần thưởng là 48000PESO, nếu giới thiệu nội bộ vị trí kỹ thuật hoặc vị trí trên cấp tổ trưởng thì phần thưởng nhận được sẽ bằng tiền lương tháng đầu của người được giới thiệu.",
    "choujiang":"Phần thưởng cho nhân viên đến sớm",
    "choujiangtit":"Nhân viên đến sớm 10 phút mỗi ngày có thể tham gia hoạt động rút thăm trúng thưởng mỗi tháng với phần thưởng là một chiếc Iphone bản mới nhất.",
    "lvyou":"Tổ chức du lịch cho nhân viên",
    "lvyouwenzi":"Công ty sẽ tổ chức hoạt động du lịch biển hoặc du lịch nước ngoài cho nhân viên xuất sắc mỗi tháng, toàn bộ chi phí sẽ do công ty chi trả.",
    "jieri":"Phúc lợi lễ tết",
    "jieriwenzi":"Nhân viên sẽ được nhận 5000PESO vào các dịp lễ như tết đoan ngọ, trung thu và năm mới, riêng tết thì nhận 10000PESO.",
    "shengri":"Tiền mừng sinh nhật",
    "jieriwenzi1":"Tiền thưởng và quà tặng phong phú",
    "shengriwenzi":"Tiền mừng sinh nhật sẽ phát theo ngày sinh trên hộ chiếu, bạn có thể nhận được quà tặng sinh nhật là 7000PESO.",
    "shengriwenzi1":"Hồng bao sinh nhật sẽ được phát vào ngày sinh nhật",
    "tuanjian":"Tiền thưởng đội nhóm",
    "tuanjianwenzi":"Đúng ngày 5 mỗi tháng sẽ phát 1000PESO, ngoài ra vào ngày không cố định công ty cũng sẽ tổ chức các hoạt động gắn kết.",
    "tuanjianwenzi1":"Nhận tiền thưởng vào ngày 5 mỗi tháng",
    "xiujia":"Trợ cấp nghỉ phép",
    "xiujiawenzi":"Có thể hưởng trợ cấp kết hôn, trợ cấp tang gia, trợ cấp thai sản hoặc trợ cấp nghỉ phép một năm về phép thăm nhà 15 ngày một lần.",
    "waisu":"Trợ cấp nhà ở",
    "waisuwenzi":"Trợ cấp thuê nhà bên ngoài sẽ được phát cố định vào ngày mùng 5 hàng tháng, mức trợ cấp sẽ có sự phân biệt với các cấp bậc như chuyên viên, tổ trưởng và chủ quản trở lên, bạn có thể nhận được trợ cấp từ 18000PESO-30000PESO.",
    "jiaotong":"Trợ cấp đi lại",
    "jiaotongwenzi":"Trợ cấp sẽ được phát mỗi tháng một lần vào ngày 5 hàng tháng, mức trợ cấp sẽ có sự phân biệt với cấp bận chủ quản trở lên, có thể nhận 2000PESO.",
    "huafei":"Trợ cấp thẻ điện thoại",
    "huafeiwenzi":"Ngày 1 mỗi tháng sẽ nhận được phí nạp điện thoại 600PESO một lần.",
    "yilaio":"Bảo hiểm y tế",
    "yiliaowenzi":"Dành cho tất cả các nhân viên đã có visa công việc (PWP hoặc 9G). Thẻ bảo hiểm y tế này sử dụng được tại các bệnh viện và phòng khám trên toàn bộ đất nước Philippines để giảm giá chi phí khám chữa bệnh. Chi phí bảo hiểm sẽ được chi trả mỗi tháng nhằm đảm bảo kỳ hạn lâu dài cho thẻ bảo hiểm.",
    "jinshi":"Trợ cấp phẫu thuật mắt cận",
    "jinwenzi":"Dựa trên số năm làm việc tại công ty, nhân viên đang làm việc tai công ty có thể được hưởng trợ cấp chi phí phẫu thuật cận thị.",
    "zhufang":"Trợ cấp nhà ở",
    "zhufangwenzi":"Trợ cấp nhà ở được phát vào ngày 5 hằng tháng",
    "shisan":"Lương tháng 13 - tháng 16",
    "shisanwenzi":"Lương tháng 13-16 được tính dựa trên thâm niên",
    "liaojie":"Muốn biết thêm về phúc lợi xã hội?"
  },
  "yuangong":{
    "tltle":"Hoạt động cho nhân viên",
    "tltle1":"Khu vui chơi của nhân viên",
    "tltle2":"Câu chuyện của nhân viên",
    "titlewenzi":"Hiện nay tập đoàn vẫn tiếp tục kế thừa  nhằm phát triển phong phú và đa dạng các loại hoạt động cho văn hóa doanh nghiệp.",
    "xuanxiang":"Tiệc mừng của tập đoàn",
    "xuanxiang1":"Du lịch trọn gói",
    "xuanxiang2":"Hoạt động thể thao",
    "xuanxiang3":"Các hoạt động ngoại khóa",
    "xuanxiang4":"Tiệc thường niên của tập đoàn",
    "yuangongwenzi":"Nhằm cải thiện đời sống nhân viên trong thời gian nghỉ ngơi,công ty đã sử dụng một khoản lớn ngân sách để xây dựng một nơi vui chơi giải trí được trang bị đầy đủ các trang thiết bị giải trí hiện đại hàng đầu, hoạt động 24h cho đội ngũ cán bộ công nhân viên.",
    "gushi":"Câu chuyện của nhân viên",
    "gushiwenzi":"Tiếng lòng nhân viên",
    "wenzitlt":"Với một trái Tim muốn đi ra ngoài và nhảy từ giáo dục đến công nghệ, CG có một nền văn hóa và quản lý nhân đạo đặc trưng, làm cho bạn cảm thấy ấm áp.",
    "wenzitlt1":"Đi sai đường thì phải tự cứu mình. Thật may mắn khi có được một khởi đầu tốt, một công ty tốt, để tôi có thể nhanh chóng thoát khỏi tình huống khó khăn.",
    "diqu":"Malaysia Người phỏng vấn",
    'gdjc':'Chi tiết hơn',
    'hsgw':'Nhanh chóng tìm kiếm vị trí thích hợp',
    'tqs':'Phòng chơi bi-a',
    'jss':'Phòng tập thể hình',
    'ktv':'KTV',
    'ct':'Phòng giải lao',
    'djs':'Phòng trò chơi giải trí'
  },
  "center":{
    "bang":"Môi trường",
    "wenzi":"Tập đoàn CG",
    "wenzidiv":"có trụ sở chính tại Makati, khu thương mại trung tâm của Metro Manila, Philippines, chúng tôi tạo ra môi trường làm việc thoải mái, mới mẻ, an toàn, thể hiện văn hóa đặc sắc của CG đến nhân viên.",
    "jiaru":"Gia nhập CG , Chung tay kiến tạo một tương lai tốt đẹp!",
    "footer":"Ứng tuyển online/ giới thiệu nội bộ"
  },
  'story':{
    'Gcontent':'Tôi là Gerry và được đồng nghiệp gọi thân mật là Nhiệt Lý.',
    'Garea':'Việt Nam',
    'Gzhiwei':'Kỹ sư kiểm nghiệm',
    'Tcontent':'Tôi là Tina, tốt nghiệp ngành giáo dục tại Đại học Putra Malaysia 2018, đi dạy cấp 2 sau một thời gian, năm 2021 tôi đã vào CG làm phỏng vấn viên.',
    'Tarea':'MALAYSIA',
    'Tzhiwei':'PHỎNG VẤN VIÊN',
    'Scontent':"Tôi là Sunny, mỗi ngày nỗ lực vì bản thân và mèo cưng sống trong ngôi nhà lớn.",
    'Sarea':'MALAYSIA',
    'Szhiwei':'Tài vụ',
    'Mcontent':"Tôi là Martin, người Indonesia. Sau cấp 3, giống nhiều người Indonesia, tôi lang thang không có trình độ, sau khi làm trong các nhà máy lớn nhỏ, tôi thấy Indonesia đã thay đổi, mức lương không hề tăng, lương tháng không đủ nuôi thân cũng chẳng thể tiết kiệm. Tôi dần nhận ra chỉ có đi xa và phấn đấu mới có thể thay đổi.",
    'Marea':'INDONESIA',
    'Mzhiwei':'NHÂN SỰ TUYỂN DỤNG',
    'Econtent':'Tôi là Eric, năm năm kinh nghiệm cho bốn công ty nước ngoài các quy mô, nhưng tôi không có sự trau dồi kinh nghiệm cũng như định hướng phát triển nghề nghiệp, như ruồi không đầu đi tìm kiếm khách, thành tích kém nên bị trừ lương, bị ép tăng ca, và bị sa thải. Vào thời điểm đó, tôi đã mất niềm đam mê với ngành bán hàng.',
    'Earea':'Malaysian',
    'Ezhiwei':'CHỦ QUẢN THỊ TRƯỜNG',
    'Kcontent':'Tôi là Kent, sau tốt nghiệp năm 18 tuổi liền gia nhập CG. Chỉ trong vài năm, tôi đã từ một người mới tôi dần dần có thể tự độc lập. Tôi thường cảm thấy mình giống miếng bọt biển, điên cuồng hút lấy nguồn dưỡng chất từ công ty và tập đoàn vậy.',
    'Karea':'Việt Nam-Kent',
    'Kzhiwei':'Chăm sóc khách hàng',

  },
  "joinUs": {
    "zzzw": "Vị trí tuyển dụng",
    "sx": "Lựa chọn",
    "zzlb": "Vị trí bộ phận",
    "gzdd": "Địa điểm làm việc",
    "dqwz": "Vị trí hiện tại",
    "gwxq": "Tuyển dụng",
    "qc": "Xóa",
    "nkngxqdzw": "Vị trí bạn muốn ứng tuyển",
    "scwzjlhqzwtj": "Vui lòng tải CV của bạn",
    "scjl": "Tải CV",
    "kstd": "Nộp ngay",
    'djsc': 'Nhấp để tải',
    'yes': 'Có',
    'no': 'Không',
    'man': 'Nam',
    'woman':'Nữ',
    'add':'Thêm',
    'kzw':'Tìm kiếm vị trí',
    'cjzp':'Chào bạn , chào mừng bạn đến với trang tuyển dụng !',
    'wsjl':'Vui lòng hoàn thiện CV vị trí bạn ứng tuyển',
    'wynl':'Trình độ ngoại ngữ',
    'dqwz': 'Vị trí hiện tại',
    'xl': 'Trình độ',
    'gwxq': 'Tuyển dụng',
    'kstd': 'Nộp ngay',
    'ssbm': 'Tất cả bộ phận',
    'gznx': 'Kinh nghiệm làm việc',
    'jcxx': 'Thông tin cơ bản',
    'zwms': 'Vị trí tuyển dụng',
    'zwyq': 'Yêu cầu',
    'zwdy': 'Lương và phúc lợi',
    'tjjl': 'Nộp đơn',
    'qxz': "Hãy chọn"
  },
  'footer':{
    'xgwz':'Các trang web liên quan',
    'cg':'Trang web CG chính thức',
    'bq':'Quyền sở hữu bởi tập đoàn CG',
    'dt':'Để nhận tin chính thức và thông tin tuyển dụng, xin hãy theo dõi và liên hệ chúng tôi'
  },
  "jianli":{
    "title":"Điền vào sơ yếu lý lịch",
    "xingming":"Họ tên",
    "xingbie":"Giới tính",
    "guoji":" Quốc tịch",
    "phone":"Điện thoại",
    "hunyin":"Tình trạng hôn nhân",
    "yihun":"Độc thân",
    "weihun":"Đã kết hôn",
    "chudata":"Ngày tháng năm sinh",
    "age":"Tuổi",
    "dizhi":" Địa chỉ hiện tại",
    "unmber":"Số Hộ Chiếu",
    "youxiao":"Thời hạn của hộ chiếu",
    "lianxi":"Liên hệ",
    "zhanghao":"Tài khoản đăng nhập",
    "yingpin":"Vị trí ứng tuyển",
    "tuijian":"Có Giới thiệu không?",
    "tuijianname":"Tên người Giới thiệu"
  },
  "jianliwz":{
    "title":"Ảnh cá nhân",
    "title1":"Dạng thức tải lên: PNG, JPG, JPEG, GIF, lên tới 5M",
    "title2":"Video giới thiệu bản thân ",
    "title3":"Dạng thức tải lên: MP4, MOV, WMV, tối đa 100M",
    "title4":"Trình độ văn hóa",
    "title5":"trường",
    "title6":"giáo dục",
    "title7":" lớn",
    "title8":"Thời gian học Kinh nghiệm làm việc",
    "title9":"Kinh nghiệm làm việc",
    "titlewenzi1":"Công ty",
    "titlewenzi2":"Vị trí",
    "titlewenzi3":"giờ làm việc",
    "titlewenzi4":"Hàm công việc",
    "titlewenzi5":"Trình độ ngoại ngữ",
    "titlewenzi6":"Tải tệp lên",  
    "titlewenzi7":"Tải CV",  
    "titlewenzi8":"Được tải 2 tệp, mỗi tệp không quá 100M, hỗ trợ các định dạng: .doc .docx .xls",  
    "titlewenzi9":"Được tải 2 tệp, mỗi tệp không quá 100M, hỗ trợ các định dạng: .doc .docx .xls",
    "zuopin":"Tải tác phẩm",
    "geshi":"Mọi định dạng",
    "xmbnwk": "Họ tên không được để trống",
    "qsrnr": "Mời nhập nội dung",
    "ypgwbnwk": "Vị trí ứng dụng không thể rỗng"
  },
}  
