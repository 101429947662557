
import Vue from 'vue'
import config from '../config'
function imgDirective (el, binding) {
    console.log(el, binding)
    const image = new Image()
    const webpUrl = binding.value.replace(/.png|.jpg$/, '.webp')
    image.src = webpUrl
    image.addEventListener('error', (target) => {
        console.log('error')
        target.onerror = null
        el.src = binding.value
    })
    image.addEventListener('load', () => {
        el.src = webpUrl
    })
}
const plugin = {
    install(Vue){
        Vue.prototype.$ossUrl = config.ossUrl
        Vue.prototype.$baseUrl = config.baseUrl
        Vue.directive('img', {
            inserted: imgDirective,
            update: imgDirective,
        })
    }
}
Vue.use(plugin)