<template>
  <div id="root__dom">
    <div id="app">
      <div class="header" style="background-color: #fff">
        <div class="header-content">
          <div class="header-content_left">
            <div class="logo">
              <img src="../static/img/index/icon_logo.webp" alt="">
            </div>
          </div>
          <div class="header-content_right">
            <div class="header-content_right_tab" v-for="item, index in tablist" :key="index" @click="toTab(index)"
              :class="tabindex === index ? 'tabactive' : ''">
              {{ item }}
            </div>
          </div>
        </div>
      </div>
      <div class="header1"></div>
    </div>
    <div class="content__view">
      <Nuxt />
      <div class="footer">
        <div class="footer-content">
          <div>
            ©方魚宮株式会社
          </div>
          <div>
            〒1500001  ｜  東京都渋谷区神宮前１丁目２３番２８号
          </div>
        </div>
      </div>
      <RightMenu />
      <el-dialog title="Mẹo" :visible.sync="dialogVisible" width="350px" style="border-radius: 10px;">
        <span>Trang web đang được xây dựng~</span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import RightMenu from "../components/RightMenu.vue";
export default {
  components: { RightMenu },
  head() {
    return {
      title: '方魚宮'
    };
  },
  created() {
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  data() {
    return {
      tabindex: 0,
      inputtext: "",
      dialogVisible: false,
      searchIndex: "",
      nowIndex: "",
      lang: [],
      headerbg: false,
      tablist: [
        'ホーム', '会社案内', 'サービス内容', '製品案内', 'ニュースルーム'
      ],
      form: {
        name: "",
        phone: "",
        content: ""
      }
    };
  },
  // computed: {
  //   showLocales() {
  //     return this.$i18n.locales;
  //   }
  // },
  methods: {
    handleScroll() {
      const domtop = document.querySelector('.index-top').offsetTop
      const domabout = document.querySelector('.index-about').offsetTop
      const domproduct = document.querySelector('.index-product').offsetTop
      const domProduct = document.querySelector('.index-Product').offsetTop
      const domnews = document.querySelector('.index-news').offsetTop
      const scrollDistance = (window.scrollY || window.pageYOffset) + 75;
      const tablist = document.querySelectorAll('.header-content_right_tab')
      if (scrollDistance <= domtop || (scrollDistance > domtop && scrollDistance < domabout)) {
        tablist[0].classList.add('tabactive')
        tablist.forEach((element, index) => {
          if (index !== 0 && element.classList.contains('tabactive')) {
            element.classList.remove('tabactive');
          }
        });
      } else if (scrollDistance > domabout && scrollDistance <= domproduct) {
        tablist[1].classList.add('tabactive')
        tablist.forEach((element, index) => {
          if (index !== 1 && element.classList.contains('tabactive')) {
            element.classList.remove('tabactive');
          }
        });
      } else if (scrollDistance > domproduct && scrollDistance <= domProduct) {
        tablist[2].classList.add('tabactive')
        tablist.forEach((element, index) => {
          if (index !== 2 && element.classList.contains('tabactive')) {
            element.classList.remove('tabactive');
          }
        });
      } else if (scrollDistance > domProduct && scrollDistance <= domnews) {
        tablist[3].classList.add('tabactive')
        tablist.forEach((element, index) => {
          if (index !== 3 && element.classList.contains('tabactive')) {
            element.classList.remove('tabactive');
          }
        });
      } else if (scrollDistance > domnews) {
        tablist[4].classList.add('tabactive')
        tablist.forEach((element, index) => {
          if (index !== 4 && element.classList.contains('tabactive')) {
            element.classList.remove('tabactive');
          }
        });
      }
      console.log(scrollDistance, domProduct);
    },
    toTab(index) {
      const domabout = document.querySelector('.index-about').offsetTop
      const domproduct = document.querySelector('.index-product').offsetTop
      const domProduct = document.querySelector('.index-Product').offsetTop
      const domnews = document.querySelector('.index-news').offsetTop
      if (index === 0) {
        window.scrollTo(0, 0)
      } else if (index === 1) {
        window.scrollTo(0, domabout)
      } else if (index === 2) {
        window.scrollTo(0, domproduct)
      } else if (index === 3) {
        window.scrollTo(0, domProduct)
      } else if (index === 4) {
        window.scrollTo(0, domnews)
      }
      //  location.href= '#' + index.toString()
    },

  }
};
</script>
<style lang="less" scoped>
#app {
  min-width: 1300px;
  overflow: auto;
}

.header {
  height: 72px;
  width: 100%;
  min-width: 1300px;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 2000;
  border-bottom: 1px solid #E1E3E7;

  &-content {
    width: 1300px;
    height: 72px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_left {
      width: 150px;
      height: 24px;
      display: flex;
      align-items: center;

      .logo {
        width: 126px;
        height: 44px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      &_title {
        margin-left: 10px;
        color: #3070EF;
        font-size: 24px;
        font-family: MidashiGoPr5-MB31;
      }
    }

    &_right {
      margin-top: 10px;
      height: 45px;
      display: flex;
      align-items: center;

      &_tab {
        font-size: 18px;
        color: #575D6C;
        padding: 12px 10px;
        margin-right: 50px;
        cursor: pointer;
      }

      .tabactive {
        border-bottom: 3px solid #3070EF;
        color: #3070EF;
      }
    }
  }
}

.bg {
  background-color: rgba(255, 255, 255, 0.1);
}

.header1 {
  height: 73px;
  width: 100%;
}

.content__view {
  min-height: calc(100vh - 367px - 70px);
  min-width: 1300px;
  margin: 0 auto;
  // overflow: auto;
  position: relative;
}

.footer {
  width: 100%;
  height: 80px;
  background-color: #2d3237;
  display: flex;
  align-items: center;
  justify-content: center;

  &-content {
    width: 1300px;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    color: #ffffff;
    opacity: 0.5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      font-size: 14px;
      font-family: GothicBBBPr5-Medium;
      color: #fff; 
    }
  }
}

a {
  text-decoration: none;
  color: #1d2025;
}

.shiyan {
  color: red;
}</style>
