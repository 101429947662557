export default {
  "seo": {
    "title": 'CG-recruit'
  },
  "app": {
    "zh": "简体",
    "en": "EN",
    "vi": "Việt",
    "tw": "繁體",
    "tjcg": "Submitted successfully"
  },
  "index": {
    "qbgw": "All posts",
    "wei": 'Position',
    "Phone": "Phone",
    "Email": "Email",
    "close": "Close"
  },
  //英文
  "header": {
    "index": "Homepage",
    "environmental": "Environmental",
    "salary": "Salary and Benefits",
    "staffcenter": "Employee Center",
    "join": "Join Us",
    "toudi": "Immediate delivery",
    "sousuo": "Enter keywords to search for jobs",
    "title":" Office Environment",
    "title1":"Dormitory Environment",
    "title2":"Restaurant Environment",
    "title3":"Lounge Environment",
  },
  //环境
  "huanjin":{
    "bghj": "Office environment",
    "zshj": "Accommodation environment",
    "cthj": "餐廳環境",
    "wenzidiv":"CG group headquartered is in Makati, Metro Central Business District, Manila, Philippines, creating a comfortable, innovative, safe office environment that reflects CG cultural characteristics for our employees.",
    "xuanxiang":"Reception Environment",
    "xuanxiang1":"Working Environment",
    "xuanxiang2":"Meeting Room Environment",
    "xuanxiang3":"Office Relaxation and Leisure Area",
    "zhusutitle":"The company provides employees with 1-4 bedroom hotel style apartments, free swimming pools and gyms, a full set of daily necessities and bedding, kitchen supplies and household appliances, giving employees a sense of home.",
    "rooms":"Single room",
    "rooms1":"Double room",
    "rooms2":"Couple Room",
    "rooms3":"Quadruple Room",
    "Restaurant":"The company provides a 24-hour cafeteria with dishes from many countries. With fresh ingredients of the season and exquisite cooking techniques,Various dishes can satisfy different taste.",
    "roomswenzi":"Nutritious Breakfast",
    "roomswenzi1":"Substantial Lunches",
    "roomswenzi2":"Delightful Dinner",
    "roomswenzi3":"Bakery Desserts",
    "roomswenzi4":"Seasonal fruit",
    "roomswenzi5":"Juice Extractor",
    "roomswenzi6":"Coffee Machine",
    "roomswenzi7":"Milk Tea Machine",
    "zhoubianwenzi":"There are many large shopping malls, convenience stores, and restaurants of various countries around the company, which can be reached within 3-5 minutes by walk, making your travel more convenient.",
    "zhouweixunaxiang":"Large Shopping Mall",
    "zhouweixunaxiang1":"Fruit Supermarket",
    "zhouweixunaxiang2":"7-11 Convenience Store",
    "zhouweixunaxiang3":"Starbucks Coffee Shop",
    "zhouweixunaxiang4":"Hot Pot Restaurant",
    "zhouweixunaxiang5":"Barbecue Restaurant",
    "zhouweixunaxiang6":"Hot Pot Restaurant",


  },
  //福利
  "fuli":{
    "title":"Salary Income",
    "title1":"Regular Benefit",
    "title2":"Holiday benefits",
    "title3":"Incentives",
    "wenzi":"The company also has a salary higher than",
    "wenzi1":"20%",
    "wenzi2":"in the same industry, and perfect and personalized employee benefits, just to repay your hard work.",
    "xinzi":"Basic base salary",
    "xinzititle":"start with 80000PESO , which are differentiated according to different positions.",
    "quanqin":"Full attendance bonus",
    "quanqint1":"Full attendance bonus be issued to those who have no abnormal attendance every month",
    "quanqintit":"4000PESO will be issued to those who without abnormalities(including belated,leaving early, missed punchcards, no sick leave, personal leave, etc.)",
    "quanqintit1":"Housing allowance will be paid on time on the fifth of every month",
    "nianzhong":"13th-16th Annual Bonus",
    "nianzhongtit":"thirteen to sixteen salaries are paid annually based on length of service",
    "zhongcheng":"Loyalty award",
    "zhongchengtit":" apply for the loyalty award after one year of employment. The marketing department can receive 240000PESO, and other departments can receive 160000PESO.",
    "changguitit":"Internal referral bonus",
    "changguiwenzi":"Internal referral marketing department will be rewarded 80000PESO, other internal referral departments will be rewarded 48000PESO, and internal referral technical talents and talents with positions above the team leader of other departments will be rewarded with their corresponding monthly salary.",
    "choujiang":"Early arrival reward",
    "choujiangtit":"Incumbents arrive 10 minutes early for work every day, and can participate in the early arrival reward benefits on the 1st of each month, and the prize is a latest version of iPhone.",
    "lvyou":"Group travel",
    "lvyouwenzi":"the company will organize excellent employees to travel to sea and abroad every month, and the whole process costs will be borne by the group.",
    "jieri":"Holiday bonus",
    "jieriwenzi":"5000PESO will be given to employees on Dragon Boat Festival, Mid Autumn Festival and new year's day, and 10000PESO will be given to employees on Spring Festival.",
    "jieriwenzi1":"Gifts and red envelopes will be issued during chinese major festivals",
    "shengri":"Birthday bonus",
    "shengriwenzi":"The birthday bonus is based on the passportdocument, you can receive 7000PESO on the same day.",
    "shengriwenzi1":"Receive birthday gifts on your birthday",
    "tuanjian":"Reunion Bonus",
    "tuanjianwenzi":"Issued on the 5th of every month, 1000PESO per person.In addition, the company organizes group reunion activities from time-to-time.",
    "tuanjianwenzi1":"Team building gifts are issued on the 5th of each month",
    "xiujia":"Paid leave",
    "xiujiawenzi":"Enjoy paid marriage leave, bereavement leave, maternity leave and other related statutory holidays, and enjoy 15 days of paid leave.",
    "waisu":"Housing allowance",
    "waisuwenzi":"Stay out allowance is paid on time on the 5th of each month. The amount is distinguished by Commissioner, team leader, supervisor and above. You can receive 18000PESO-30000PESO.",
    "jiaotong":"Transportation allowance",
    "jiaotongwenzi":"The transportation allowanceis paid on time on the 5th of each month. The amount is distinguished by the supervisor, and can get 2000PESO.",
    "huafei":"Telephone fee allowance",
    "huafeiwenzi":"recharge the telephone fee of 600PESO on time on the 1st of each month.",
    "yilaio":"Medical insurance",
    "yiliaowenzi":"Apply for Philippine medical insurance card for all employees who have applied for work permit (PWP or 9g). This medical insurance card can be used to see a doctor in a designated hospital in the Philippines to deduct medical expenses. The company will pay medical insurance expenses for on-the-job employees every month to ensure the long-term validity of the medical insurance card.",
    "jinshi":"Myopic microsurgery allowance",
    "jinwenzi":"provide myopic microsurgery expense subsidy for myopic on-the-job employees, and reimburse the operation expenses according to the length of service.",
    "zhufang":"Housing allowance",
    "zhufangwenzi":"Stay out allowance is paid on time on the 5th of each month",
    "shisan":"13th-16th Annual bonus",
    "shisanwenzi":"13th-16th Annual Bonus are paid annually based on length of service.",
    "liaojie":"Want to know more about benefits?"
  },
  "yuangong":{
    "tltle":"Employee Activities",
    "tltle1":"Employee Club",
    "tltle2":"Employee Stories",
    "titlewenzi":"With a rich and variety of activities and a depth of cultural branding, will be Group's cultural beliefs are continuously passed on.",
    "xuanxiang":"Group Dinner Party",
    "xuanxiang1":"Charter Tour",
    "xuanxiang2":"Sports Activities",
    "xuanxiang3":"Outreach Activities",
    "xuanxiang4":"Group Annual Party ",
    "yuangongwenzi":"To enrich the spare time of employees, the Group has spent a lot of money to build a staff club.The club is equipped with top-quality activity facilities and is open 24 hours for employees.",
    "gushi":"Employee Stories",
    "gushiwenzi":"Senior Said ",
    "wenzitlt":"With A HEART OF WANTING TO MAKE A BREAKTHROUGH, CG GROUP HAS JUMPED FROM THE EDUCATIONAL FIELD TO THE FIELD OF SCIENCE AND TECHNOLOGY, WITH ITS DISTINCTIVE GROUP culture and humanistic management, YOU will FEEL the warmth of HOME.",
    "wenzitlt1":"The only way to recuperate from the wrong path you took is by self. Very lucky, I met a good start and a good company, which enabled me to quickly get out of difficult situation.",
    "diqu":"Malaysia The interviewer",
    'gdjc':'More Details',
    'hsgw':'Search for the suitable Jobs now',
    'tqs':'Billiards Room',
    'jss':'Fitness Room',
    'ktv':'KTV',
    'ct':'Team Building Restaurant',
    'djs':'Gaming Room'
  },
  "center": {
    "bang": "Environmental",
    "wenzi":"CG ",
    "wenzidiv": "headquartered is in Makati, Metro Central Business District, Manila, Philippines, creating a comfortable, innovative, safe office environment that reflects CG cultural characteristics for our employees.",
    "jiaru":"Join CG, Let us get together and create a beautiful future！",
    "footer":"Online Application / Internal Referrals"
  },
  'story':{
    'Gcontent':'I am Gerry, my colleagues in the company like to call me Gerry.',
    'Garea':'Vietnamese',
    'Gzhiwei':'Test Engineer',
    'Tcontent':'My name is Tina, I graduated from Universiti Putra Malaysia in 2018 with a degree in Education and worked as a secondary school teacher for a while. 2021, I joined CG Group and now I am working as an interviewer related job.',
    'Tarea':'Malaysian',
    'Tzhiwei':'Interviewer',
    'Scontent':"I'm Sunny, and every day I'm working hard for me and my cat to live in a big house.",
    'Sarea':'Malaysian',
    'Szhiwei':'Financial',
    'Mcontent':"I am Martin, a native Indonesian. After graduating from high school, like many Indonesians, I drifted around without a high level of education, and after staying in both large and small factories, I found that the Indonesian side has changed. I can't save a little bit of savings. I slowly found that going out to struggle to change the status quo.",
    'Marea':'Indonesian',
    'Mzhiwei':'Human Resources Recruiter',
    'Econtent':'My name is Eric, in overseas work for 5 years, joined 4 large and small companies, in the former company, basically no personal ability and professional skills training, just let us use their own way, like headless flies type to develop customers, performance is not enough all kinds of pay deductions, overtime, no method and mediocre performance, I only have the fate of being dismissed. At that time, I lost my enthusiasm for the industry sales.',
    'Earea':'Malaysian',
    'Ezhiwei':'Marketing Executive',
    'Kcontent':'I am Kent, I joined CG Group after graduating in 18 years, and during these years, from being a newcomer to gradually being able to hold my own, I often feel like a sponge, absorbing the nutrients outputted to us by the company and the team.',
    'Karea':'Vietnamese',
    'Kzhiwei':'Customer service',
  },
  "joinUs": {
    "zzzw": "Available jobs",
    "sx": "Filter",
    "zzlb": "Position category",
    "gzdd": "Work location",
    "dqwz": "Current Location",
    "gwxq": "Jobs",
    "qc": "Clear",
    "nkngxqdzw": "Jobs you may be interested in",
    "scwzjlhqzwtj": "Upload your full resume to get job recommendations",
    "scjl": "Upload Resume",
    "kstd": "Submit Now",
    'djsc': 'Click to upload',
    'yes': 'Yes',
    'no': 'No',
    'man': 'Male',
    'woman':'Female',
    'add':'Add',
    'kzw':'Go to the job',
    'cjzp':'Hello, welcome to CG Social Recruitment!',
    'wsjl':'Please complete your resume and submit your desired position',
    'wynl':'Language Skill',
    'dqwz': 'Current Location',
    'gwxq': 'Jobs',
    'xl': 'Education',
    'kstd': 'Submit Now',
    'ssbm': 'Department',
    'gznx': 'Experience of work',
    'jcxx': 'Basic information',
    'zwms': 'Job description',
    'zwyq': 'Job requirements',
    'zwdy': 'Job treatment',
    'tjjl': 'Submit',
    'qxz': "Please select"
  },
  'footer':{
    'xgwz':'Related Websites',
    'cg':'CG official website',
    'bq':'Copyright by CG Group',
    'dt':'Real-time news and recruitment information, follow us or contact us',
  },
  "jianli":{
    "title":"Fill in your resume",
    "xingming":"Full name",
    "xingbie":"Gender",
    "guoji":"Nationality",
    "phone":"Phone number",
    "hunyin":"Marital status",
    "yihun":"married ",
    "weihun":"unmarried",
    "chudata":"Date of birth ",
    "age":"Age ",
    "dizhi":"Current address",
    "unmber":"Passport Number",
    "youxiao":"Passport expiry date ",
    "lianxi":"Contact",
    "zhanghao":"Account",
    "yingpin":"Application Position",
    "tuijian":"Employee Referrals",
    "tuijianname":"Referral’s name"
  },
  "jianliwz":{
    "title":"Personal photos",
    "title1":"Upload format: PNG, JPG, JPEG, GIF, up to 5M",
    "title2":"Self introduction video  ",
    "title3":"Upload format: MP4, MOV, WMV, maximum 100M",
    "title4":"Educational level",
    "title5":"School",
    "title6":"Education ",
    "title7":"Major",
    "title8":"Study time ",
    "title9":" Work experience ",
    "titlewenzi1":"Company",
    "titlewenzi2":"Position",
    "titlewenzi3":"Years of experience",
    "titlewenzi4":"Job Description",
    "titlewenzi5":"Language Skill ",
    "titlewenzi6":"Attachment upload",  
    "titlewenzi7":"Resume attachment",  
    "titlewenzi8":"Two attachments can be uploaded, each no more than 100M, and the supported format: doc、. docx、. xls...",  
    "titlewenzi9":"Two attachments can be uploaded, each no more than 100M, and the supported format: doc、. docx、. xls...",
    "zuopin":"Work Attachment",
    "geshi":"All formats",
    "xmbnwk": "Name cannot be empty",
    "qsrnr": "Please enter content",
    "ypgwbnwk": "Applied position cannot be empty"
  },
}
