// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/font/Bebas-Regular.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"NUM\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.num__text{font-family:NUM}body{font-family:PingFangSC-Regular,PingFang SC;margin:0}body::-webkit-scrollbar{background-color:#fff;height:8px;width:5px}body::-webkit-scrollbar-thumb{background-color:#e2e5e6}input,textarea{-webkit-user-select:text;-moz-user-select:text;user-select:text}.el-card{border-radius:16px!important}.el-image .image-slot{background:#eff0f2;height:100%;width:100%}.scrollbar::-webkit-scrollbar{background-color:#fff;height:8px;width:5px}.scrollbar::-webkit-scrollbar-thumb{background-color:#e2e5e6}.el-picker-panel.el-date-picker.el-popper,.el-select-dropdown.el-popper{left:auto!important;top:auto!important}@media only screen and (max-width:500px){.el-dialog{width:80%!important}.el-title{font-size:20px!important}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
