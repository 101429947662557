export const state = () => ({
  lang: 'zh',
  userJoinDialog: false,
})

export const mutations = {
  SET_LANG(state, lang) {
    state.lang = lang
  },
  SET_USER_JOIN_DIALOG(state, status) {
    state.userJoinDialog = status
  },
}