import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from './components/nuxt-error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_axios_826dbfba from 'nuxt_plugin_axios_826dbfba' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_pluginrouting_6b30f07a from 'nuxt_plugin_pluginrouting_6b30f07a' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_1e75328c from 'nuxt_plugin_pluginmain_1e75328c' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_version_08775ebc from 'nuxt_plugin_version_08775ebc' // Source: ../plugins/version (mode: 'all')
import nuxt_plugin_common_7195563e from 'nuxt_plugin_common_7195563e' // Source: ../plugins/common (mode: 'all')
import nuxt_plugin_customerRoute_6a893155 from 'nuxt_plugin_customerRoute_6a893155' // Source: ../plugins/customerRoute (mode: 'all')
import nuxt_plugin_customerMessage_595427da from 'nuxt_plugin_customerMessage_595427da' // Source: ../plugins/customerMessage (mode: 'all')
import nuxt_plugin_directive_36717199 from 'nuxt_plugin_directive_36717199' // Source: ../plugins/directive (mode: 'all')
import nuxt_plugin_storeCache_808829d2 from 'nuxt_plugin_storeCache_808829d2' // Source: ../plugins/storeCache (mode: 'client')
import nuxt_plugin_echarts_295016f4 from 'nuxt_plugin_echarts_295016f4' // Source: ../plugins/echarts (mode: 'client')
import nuxt_plugin_elementui_d905880e from 'nuxt_plugin_elementui_d905880e' // Source: ../plugins/element-ui (mode: 'all')
import nuxt_plugin_bus_caead7e8 from 'nuxt_plugin_bus_caead7e8' // Source: ../plugins/bus (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"","htmlAttrs":{"lang":"VI"},"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, user-scalable=no,initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0"},{"name":"description","content":"Trang mạng Sao Thần tài chính, tập hợp tất cả các loại dữ liệu và thông tin trong ngành đầu tư tài chính, dữ liệu và thông tin nhanh chóng, chính xác và đầy đủ. Cam kết cung cấp tin tức tài chính toàn cầu theo thời gian thực 24 giờ cho phần lớn các nhà đầu tư tài chính. Sao Thần tài chính sử dụng giải thích dữ liệu bằng đồ họa để làm cho dữ liệu tài chính trở nên trực quan và dễ hiểu hơn. Đồng thời, nó cung cấp một tập hợp các thông tin quốc tế quan trọng, tin tức tài chính về dữ liệu và sự kiện kinh tế toàn cầu, tin tức thị trường tài chính và tổng hợp thông tin tài chính theo thời gian thực. Chúng tôi là một đơn vị dịch vụ thông tin tài chính chuyên nghiệp, thiết thực, thuận tiện và toàn diện trong ngành."},{"name":"referrer","content":"no-referrer"},{"name":"keywords","content":"vietnam finance,Thông tin thuê bao Viettel,Tài chính luân phiên 7x24 giờ,cán tài chính,chứng khoán sao,tài chính ngôi sao,trợ giúp đầu tư,phương tiện đầu tư,"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Fimg\u002Fheader\u002Flogo.ico"},{"rel":"stylesheet","type":"text\u002Fcss","href":"\u002Fcss\u002Fswiper.min.css"}],"script":[{"src":"\u002Fjs\u002Fswiper.min.js"}],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_axios_826dbfba === 'function') {
    await nuxt_plugin_axios_826dbfba(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_6b30f07a === 'function') {
    await nuxt_plugin_pluginrouting_6b30f07a(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_1e75328c === 'function') {
    await nuxt_plugin_pluginmain_1e75328c(app.context, inject)
  }

  if (typeof nuxt_plugin_version_08775ebc === 'function') {
    await nuxt_plugin_version_08775ebc(app.context, inject)
  }

  if (typeof nuxt_plugin_common_7195563e === 'function') {
    await nuxt_plugin_common_7195563e(app.context, inject)
  }

  if (typeof nuxt_plugin_customerRoute_6a893155 === 'function') {
    await nuxt_plugin_customerRoute_6a893155(app.context, inject)
  }

  if (typeof nuxt_plugin_customerMessage_595427da === 'function') {
    await nuxt_plugin_customerMessage_595427da(app.context, inject)
  }

  if (typeof nuxt_plugin_directive_36717199 === 'function') {
    await nuxt_plugin_directive_36717199(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_storeCache_808829d2 === 'function') {
    await nuxt_plugin_storeCache_808829d2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_echarts_295016f4 === 'function') {
    await nuxt_plugin_echarts_295016f4(app.context, inject)
  }

  if (typeof nuxt_plugin_elementui_d905880e === 'function') {
    await nuxt_plugin_elementui_d905880e(app.context, inject)
  }

  if (typeof nuxt_plugin_bus_caead7e8 === 'function') {
    await nuxt_plugin_bus_caead7e8(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
