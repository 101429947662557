import en from '../lang/en-US.js'
import vi from '../lang/vi-VI.js'
import tw from '../lang/zh-TW.js'
import zh from '../lang/zh-CN.js'

export default {
  // baseUrl: 'http://jobs-api.colorfulgroup.com', // 生产
  // baseUrl: 'http://192.168.8.198:8080',
  // baseUrl: 'http://34.124.184.12:8080',
  baseUrl: 'https://saothantc.com',
  socketUrl: '35.198.237.180:8081',
  // socketUrl: '192.168.8.198:8081',
  // baseUrl: 'http://vpn.cncf.online:30100',
  // ossUrl: 'https://zposs.qazxswq876.com/'
}

export const I18N = {
  locales: [
    {
      code: 'zh',
      iso: 'zh-CN',
      name: '简体'
    },
    {
      code: 'tw',
      iso: 'zh-TW',
      name: '繁体'
    },
    {
      code: 'vi',
      iso: 'vi-VI',
      name: '越南语'
    },
    {
      code: 'en',
      iso: 'en-US',
      name: '英文'
    }
  ],
  defaultLocale: 'zh',
  vueI18n: {
    fallbackLocale: 'zh',
    messages: { en, vi, tw, zh }
  }
}
