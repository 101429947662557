<template>
  <div class="menu">
    <div class="menu-circle1">
      <img class="menu-circle1-img" src="../static/img/index/icon_ser.svg" />
      <div>無料のお問い合わせはこちら</div>
    </div>
    <div class="menu-circle" @click="toTopHandle">
      <img class="menu-circle-img2" src="../static/img/index/icon_top.svg" />
    </div>

  </div>
</template>

<script>
export default {
  name: "RightMenu",
  methods: {
    toTopHandle() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="less" scoped>
.menu {
  position: fixed;
  right: 24px;
  top: 50% !important;

  &-circle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    margin-bottom: 12px;
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 0px 2px 16px 0px rgba(37, 43, 58, 0.12);
    cursor: pointer;

    img {
      width: 20px;
      height: 20px;
    }

    &-img2 {
      display: block;
    }


  }

  &-circle1 {
    width: 48px;
    height: 310px;
    border-radius: 24px;
    background-color: rgba(48, 112, 239, 1);
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;

    div {
      font-size: 15px;
      font-family: PingFangSC-Medium;
      color: #fff;
      width: 16px;
      height: 84px;
      margin-top: 7px;
    }

    img {
      margin-top: 20px;
      width: 17.15px;
      height: 16px;
    }
  }
}</style>